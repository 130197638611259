@mixin __FF_PWA_MODE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/dt-base.scss';

.main {
  padding-top: 0px;
  position: relative;
  min-height: calc(100vh - #{$minimal-layout-footer-min-height-xs});

  @include media($min-sm) {
    min-height: calc(100vh - #{$minimal-layout-footer-min-height-sm});
  }

  @include media($min-md) {
    min-height: calc(100vh - #{$minimal-layout-footer-min-height-md});
  }
}
