@mixin __FF_PWA_MODE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

.text-input {
  width: 100%;
  height: 100%;
  padding: 20px;
  font-size: rem(16);

  &:disabled {
    background-color: $dt-light-medium-gray;
    color: $medium-light-gray;
  }
}
