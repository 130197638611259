@mixin __FF_PWA_MODE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

:global {
  body {
    background: #fafafa;
  }
  #root {
    max-width: 1200px;
    margin: auto;
    background: white;
  }
}

.home-page-wrapper {
  max-width: 1200px;
}

.lets_img {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.register-form {
  padding: 0 20px;
  min-height: calc(100vh - 700px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 450px;
  margin: auto;
}

.submit_button {
  position: relative;
  width: 200px;
  margin: auto;
  margin-top: 30px;
}
