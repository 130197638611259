@mixin __FF_PWA_MODE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

.checkbox {
  align-items: center;
  display: inline-flex;
  user-select: none;

  label {
    cursor: pointer;
  }
}

.checkbox-disabled {
  @extend .checkbox;
  cursor: no-drop;
  pointer-events: none;
}

.container {
  cursor: pointer;
  margin-right: 8px;
  position: relative;

  input {
    position: absolute;
    opacity: 0;

    &:checked + .display::after {
      background: $dt-blue;
      border-radius: 2px;
      content: '';
      height: 14px;
      left: 2px;
      position: absolute;
      top: 2px;
      width: 14px;
      z-index: 1;
    }
  }
}

.display {
  align-items: center;
  background: #fff;
  border-radius: 2px;
  border: 1px solid $dark-gray;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  position: relative;
  width: 20px;

  svg {
    position: relative;
    z-index: 2;
  }
}
