@mixin __FF_PWA_MODE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px -20px 0;
  min-height: calc(100vh - 750px);
}

.cell {
  width: 100%;
  border: 0 solid transparent;
  border-width: 0 20px 20px 0;

  @include media($min-sm) {
    width: 50%;
  }
}

.cell:empty {
  border-width: 0;
}
