@mixin __FF_PWA_MODE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/_dt-base.scss';

.default {
  position: absolute;
  top: 12px;
  padding: 0 15px;
  z-index: 2;
  transition: top 0.25s ease-out, font-size 0.25s ease-out;
  color: $medium-light-gray;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 2px);

  &::before {
    content: '';
    position: absolute;
    width: calc(100% - 30px);
    height: 4px;
    background: $white;
    top: 10px;
    left: 0;
    z-index: -1;
  }
}

.minimize {
  top: -11px;
  left: 11px;
  padding: 0 10px;
  font-size: rem(13);

  &::before {
    width: 100%;
  }
}

.required {
  color: $dt-red;
  font-size: rem(14);
}
