// Font Sizes
$base-font-size: 16px;

$content-max-width: 1200px;
$content-max-width-xl: 1600px;

$minimal-layout-footer-min-height-xs: 267px;
$minimal-layout-footer-min-height-sm: 263px;
$minimal-layout-footer-min-height-md: 214px;

$mobile-carousel-height: 300px;
$desktop-carousel-height: 370px;
